import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <h1 className="h1-6 title mx-auto">WHAT A SHAME</h1>
    <footer className="title blockquote-footer mx-auto">J.C Denton, <cite title="Source Title">Deus Ex</cite></footer>

    <p className="mx-auto">{`This page does not exist. If you think this is a mistake, contact Oli. Much love <3`} </p>
    <Link className="black-href" to="/">←</Link>

  </Layout>
)

export default NotFoundPage
